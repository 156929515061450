import Cookie from './Cookie'
import CTA from './CTA'
import Footer from './Footer'
import GoogleMap from './GoogleMap'
import Header from './Header'
import Loading from './Loading'
import Navbar from './Navbar'

const Components = { Cookie, CTA, Footer, GoogleMap, Header, Loading, Navbar }
export default Components
