import React, { ReactNode } from 'react'

import classnames from 'classnames'

//  ///////////////////////
//  SECTION: TYPES
export type TThemeRadioBaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export interface IThemeRadioProps extends TThemeRadioBaseProps {
  id?: string
  className?: string

  values: [value: string, label: ReactNode, id?: string][]

  name: string
  touched?: boolean
  error?: string

  disabled?: boolean
  loading?: boolean
}

//  ///////////////////////
//  SECTION: EXPORTS
const Radio: React.FC<IThemeRadioProps> = ({
  children,
  id,
  className,
  values,
  touched,
  error,
  ...props
}) => {
  const { required, disabled, loading } = props

  const elementClassName = classnames(
    'tRadio',
    {
      'tRadio--filled': props.value,
      'tRadio--error': touched && error,
      'tRadio--touched': touched,
      'tRadio--required': required,
      'tRadio--disabled': disabled,
      'tRadio--loading': loading,
    },
    className
  )

  const subElementClassName = classnames('tRadio__group', {
    'tRadio__group--filled': props.value,
    'tRadio__group--error': touched && error,
    'tRadio__group--touched': touched,
    'tRadio__group--required': required,
    'tRadio__group--disabled': disabled,
    'tRadio__group--loading': loading,
  })

  return (
    <>
      <div id={id} className={elementClassName}>
        <div className={subElementClassName}>
          {values.map(([value, label, valueId], i) => {
            const inputId = `input_radio_${props.name}_${valueId ?? i}`
            return (
              <div key={i} className='tRadio__group__value'>
                <input
                  {...props}
                  id={inputId}
                  type='radio'
                  value={value}
                  checked={props.value === value}
                />
                <label htmlFor={inputId}>{label}</label>
              </div>
            )
          })}
        </div>
        <div className='tRadio__state'>
          {touched && error && <small className='tRadio__state__error'>{error}</small>}
        </div>
      </div>
    </>
  )
}

export default Radio
