import React, { useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'

import Router from 'router/_controller'

import { formatLocales } from 'hooks/useFormattedLocales'
import { useGlobalContext } from 'providers/Global'

import Config from 'utils/config'

import ScrollToTop from 'helpers/ScrollToTop'

import Components from 'components/_all'

// ///////////////////////
// SECTION: EXPORTS
const App: React.FC = () => {
  const { Locale } = useGlobalContext()!

  // ///////////////////////
  // LOAD LOCALE
  const [messages, setMessages] = useState<{
    [key: string]: string
  } | null>(null)

  useEffect(() => {
    // Try to load locale
    import(`locales/${Locale.active}.json`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .then((data) => setMessages(formatLocales(data.default)))
      .catch(() => {})
  }, [Locale.active])
  // ///////////////////////

  if (!messages) {
    return <Components.Loading />
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <IntlProvider locale={Locale.active} messages={messages} onError={() => {}}>
        <GoogleReCaptchaProvider
          reCaptchaKey={Config.apis.google_recpatcha_site_key}
          language={Locale.active.slice(0, 2) ?? 'en'}
        >
          <Components.Navbar />
          <div className='content'>
            <React.Suspense fallback={<Components.Loading />}>
              <Router />
            </React.Suspense>
          </div>
          <Components.Footer />
          <Components.Cookie />
        </GoogleReCaptchaProvider>
      </IntlProvider>
    </BrowserRouter>
  )
}

export default App
