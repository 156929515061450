import { useCallback } from 'react'

//  ///////////////////////
//  SECTION: CONFIG
const DEFAULT_OPTIONS: IUseFocusActionOptions = {
  keyboardPreventDefault: true,
  keyboardStopPropagation: true,

  keyboardKeyEnter: true,
  keyboardKeySpace: true,
}

// ///////////////////////
// SECTION: EXPORTS
export interface IUseFocusActionOptions {
  keyboardPreventDefault?: boolean
  keyboardStopPropagation?: boolean

  keyboardKeyEnter?: boolean
  keyboardKeySpace?: boolean
}

// ///////////////////////
// SECTION: EXPORTS
export default function useFocusAction(
  callback: (type: 'mouse' | 'key') => void,
  options = DEFAULT_OPTIONS
): [keyboard: (e: React.KeyboardEvent<HTMLElement>) => void] {
  const keyboard = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (
        (options.keyboardKeyEnter && e.key === 'Enter') ||
        (options.keyboardKeySpace && e.key === ' ')
      ) {
        if (options.keyboardPreventDefault) e.preventDefault()
        if (options.keyboardStopPropagation) e.stopPropagation()

        callback('key')
      }
    },
    [
      callback,
      options.keyboardKeyEnter,
      options.keyboardKeySpace,
      options.keyboardPreventDefault,
      options.keyboardStopPropagation,
    ]
  )

  return [keyboard]
}
