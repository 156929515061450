import React from 'react'

import { TRouter } from './types'
import { flattenRoutes } from './utils'

//  ///////////////////////
//  SECTION: CONFIG
export const BASE_ROUTE = 'https://www.kr-mediation.be'

//  ///////////////////////
//  SECTION: CODE-SPLITTING
const ViewIndex = React.lazy(() => import('../views/_index/View'))
const ViewE404 = React.lazy(() => import('../views/_e404/View'))

const ViewLaMediation = React.lazy(() => import('../views/la_mediation/View'))
const ViewLesAvantages = React.lazy(() => import('../views/les_avantages/View'))
const ViewQuelsLitiges = React.lazy(() => import('../views/quels_litiges/View'))
const ViewVotreMediateur = React.lazy(() => import('../views/votre_mediateur/View'))
const ViewHonoraires = React.lazy(() => import('../views/honoraires/View'))
const ViewRendezVous = React.lazy(() => import('../views/rendez_vous/View'))

const ViewLegalsLegalDisclaimer = React.lazy(() => import('../views/_legals/LegalDisclaimer'))
const ViewLegalsPrivacyPolicy = React.lazy(() => import('../views/_legals/PrivacyPolicy'))

//  ///////////////////////
//  SECTION: EXPORTS
export const Routes: TRouter = [
  {
    path: '/',
    options: {
      element: ViewIndex,
    },
  },
  {
    path: '/la-mediation',
    options: {
      element: ViewLaMediation,
    },
  },
  {
    path: '/les-avantages',
    options: {
      element: ViewLesAvantages,
    },
  },
  {
    path: '/quels-litiges',
    options: {
      element: ViewQuelsLitiges,
    },
  },
  {
    path: '/votre-mediateur',
    options: {
      element: ViewVotreMediateur,
    },
  },
  {
    path: '/honoraires',
    options: {
      element: ViewHonoraires,
    },
  },
  {
    path: '/rendez-vous',
    options: {
      element: ViewRendezVous,
    },
  },
  // SECTION: LEGALS ROUTES
  {
    path: '/mentions-legales',
    options: {
      element: ViewLegalsLegalDisclaimer,
      hideSitemap: true,
    },
  },
  {
    path: '/vie-privee',
    options: {
      element: ViewLegalsPrivacyPolicy,
      hideSitemap: true,
    },
  },
  // SECTION: 404
  {
    path: '*',
    options: {
      element: ViewE404,
    },
  },
]

export const [ROUTES, ROUTES_MAP, ROUTES_REVERSED_MAP, ROUTES_INFO_MAP] = flattenRoutes(Routes)
