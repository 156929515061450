import React, { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import classnames from 'classnames'
import LinkRouter from 'router/_link'

import { useGlobalContext } from 'providers/Global'

import { gaAcceptCookies, gaRejectCookies } from 'utils/analytics'

import FormattedRichMessage from 'helpers/FormattedRichMessage'

import Theme from 'theme/_all'

// ///////////////////////
// SECTION: TYPES
export interface IComponentsCookieProps {
  id?: string
  className?: string
}

// ///////////////////////
// SECTION: EXPORTS
const Cookie: React.FC<IComponentsCookieProps> = ({ id, className }) => {
  const { Auth } = useGlobalContext()!

  const enable = useCallback(() => {
    Auth.cookie(true)
  }, [Auth])

  const disable = useCallback(() => {
    Auth.cookie(false)
  }, [Auth])

  useEffect(() => {
    if (Auth.cookieSet) {
      gaAcceptCookies()
    } else {
      gaRejectCookies()
    }
  }, [Auth.cookieSet])

  if (Auth.cookieSet) return null

  // ClassNames
  const elementClassName = classnames('cCookie', className)

  // Return
  return (
    <div id={id} className={elementClassName}>
      <p>
        <FormattedRichMessage
          id='c.cookie.text'
          values={{
            link: (
              <LinkRouter className='link link--white' to='/vie-privee'>
                <FormattedMessage id='lang.privacy_policy' />
              </LinkRouter>
            ),
          }}
        />
      </p>
      <div className='cCookie__buttons'>
        <div>
          <Theme.Button
            size='s'
            icon='fas.fa-check'
            onClick={enable}
            eventCategory='Cookies'
            eventAction='Accept'
          >
            <FormattedMessage id='c.cookie.accept' />
          </Theme.Button>
        </div>
        <div>
          <Theme.Button
            size='s'
            icon='fas.fa-times'
            onClick={disable}
            eventCategory='Cookies'
            eventAction='Reject'
          >
            <FormattedMessage id='c.cookie.reject' />
          </Theme.Button>
        </div>
      </div>
    </div>
  )
}

export default Cookie
