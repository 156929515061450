import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import classnames from 'classnames'

import useCalculatedSize from 'hooks/useCalculatedSize'

// ///////////////////////
// SECTION: TYPES
export interface IComponentsHeaderProps {
  id?: string
  className?: string

  parallaxAmount?: number

  img: string
}

// ///////////////////////
// SECTION: EXPORTS
const Header: React.FC<IComponentsHeaderProps> = ({
  id,
  className,
  parallaxAmount = 0.5,
  img,
  children,
}) => {
  const [contentRef, , contentHeight] = useCalculatedSize<HTMLDivElement>()

  // ClassNames
  const elementClassName = classnames('cHeader', 'container', className)

  // Return
  return (
    <ParallaxBanner
      layers={[
        {
          image: img,
          amount: parallaxAmount,
        },
      ]}
      style={{
        height: `${contentHeight}px`,
      }}
    >
      <header ref={contentRef} id={id} className={elementClassName}>
        <div className='row'>
          <div className='col-lg-8 col-12 mx-auto'>{children}</div>
        </div>
      </header>
    </ParallaxBanner>
  )
}

export default Header
