import { useMemo } from 'react'

//  ///////////////////////
//  SECTION: FUNCTIONS
export type TMessagesLocales = { [key: string]: string }

//  ///////////////////////
//  SECTION: FUNCTIONS
export const formatLocale = (value: string): string => {
  value = value.replaceAll(/ ([!:;?])/g, '\u00A0$1')

  return value
}

export const formatLocales = (locales: TMessagesLocales): TMessagesLocales => {
  const value = { ...locales }

  for (const key of Object.keys(value)) {
    value[key] = formatLocale(value[key])
  }

  return value
}

// ///////////////////////
// SECTION: EXPORTS
export default function useFormattedLocales(
  locales: TMessagesLocales | null
): TMessagesLocales | null {
  const localesFormatted = useMemo<TMessagesLocales | null>(() => {
    if (!locales) return null
    return formatLocales(locales)
  }, [locales])

  return localesFormatted
}
