import Config from './config'

export const GA_TRACKING_ID = Config.apis.google_analytics_tracking_id

export const gaAcceptCookies = (): void => {
  window.gtag('config', GA_TRACKING_ID, {
    anonymize_ip: false,
  })
}

export const gaRejectCookies = (): void => {
  window.gtag('config', GA_TRACKING_ID, {
    anonymize_ip: true,
  })
}

export const gaPageView = (url: URL): void => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

export const gaEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string
  category: string
  label: string
  value: number
}): void => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}

export const gAdsEvent = ({
                          action,
                            send_to
                        }: {
  action: string
  send_to: string
}): void => {
  window.gtag('event', action, {send_to, event_callback: ()=> {}})
}