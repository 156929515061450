import { RefObject, useEffect, useRef, useState } from 'react'

// ///////////////////////
// SECTION: EXPORTS
export default function useCalculatedSize<T extends HTMLElement>(): [
  ref: RefObject<T>,
  width: number,
  height: number
] {
  const ref = useRef<T>(null)
  const [sizes, setSizes] = useState<{
    width: number
    height: number
  }>({
    width: 0,
    height: 0,
  })

  const updateSizes = (element: T) => {
    const { width, height } = element.getBoundingClientRect()
    if (height !== sizes.height || width !== sizes.width) {
      setSizes({
        width,
        height,
      })
    }
  }

  useEffect(() => {
    if (!ref.current) return

    const { current } = ref

    updateSizes(current)

    const listener = (e: UIEvent) => updateSizes(current)
    window.addEventListener('resize', listener)
    return () => window?.removeEventListener('resize', listener)
  })

  return [ref, sizes.width, sizes.height]
}
