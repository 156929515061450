import { TLocales } from 'services/locale'

import Config from './config'

//  //////////////////////////////////////////////
//  SECTION: TYPES
export enum EFetchServerBodyType {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
}

export interface IFetchServerResponse {
  success: boolean
  message?: string
}

export interface IFetchServerOptions {
  method?: string
  mode?: RequestMode
  cache?: RequestCache
  headers?: {
    [key: string]: string
  }
  params?: {
    [key: string]: string | number | boolean | undefined
  }
  body?:
    | {
        [key: string]: unknown
      }
    | FormData
  bodyType?: EFetchServerBodyType
}

//  //////////////////////////////////////////////
//  SECTION: EXPORTS
export function fetchServer<T>(
  jwt: string | null,
  locale: TLocales,

  endpoint: string,
  options: IFetchServerOptions = {},
  abortSignal?: AbortSignal
): Promise<T> {
  const {
    method = 'GET',
    mode = 'cors',
    cache = 'default',
    headers = {},
    params = {},
    body,
    bodyType = EFetchServerBodyType.JSON,
  } = options

  let query = `${process.env.NODE_ENV === 'production' ? Config.apis.backend : ''}/api${endpoint}`

  if (Object.keys(params).length > 0) {
    const searchParams = new URLSearchParams()

    for (const [key, value] of Object.entries(params)) {
      if (typeof value !== 'undefined') {
        searchParams.append(key, typeof value !== 'string' ? encodeURIComponent(value) : value)
      }
    }

    query += `?${searchParams.toString()}`
  }

  const queryHeaders = new Headers()

  for (const [k, v] of Object.entries(headers)) {
    queryHeaders.append(k, v)
  }

  if (jwt) queryHeaders.append('Authorization', `Bearer ${jwt}`)
  queryHeaders.append('Accept-Language', locale)

  let bodyParsed: string | FormData | undefined

  if (bodyType === EFetchServerBodyType.FORM_DATA) {
    bodyParsed = body as FormData
  } else if (body) {
    bodyParsed = JSON.stringify(body)
    queryHeaders.append('Content-Type', 'application/json')
  }

  return new Promise((resolve, reject) => {
    fetch(query, {
      method,
      mode,
      cache,
      headers: queryHeaders,
      body: bodyParsed,
      signal: abortSignal,
    })
      .then((response) => {
        return response.json()
      })
      .then(resolve)
      .catch((err: Error) => {
        if (err.name === 'AbortError') return

        reject(err)
      })
  })
}
