import React, { useMemo } from 'react'

import classnames from 'classnames'

import SVG from 'assets/svg/_SVG'

//  ///////////////////////
//  SECTION: TYPES
export type TThemeInputBaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

export interface IThemeInputProps extends TThemeInputBaseProps {
  id?: string
  className?: string

  inputType?: 'input' | 'textarea'

  name: string
  touched?: boolean
  error?: string

  disabled?: boolean
  loading?: boolean
}

//  ///////////////////////
//  SECTION: EXPORTS
const Input: React.FC<IThemeInputProps> = ({
  children,
  id,
  className,
  inputType = 'input',
  touched,
  error,
  ...props
}) => {
  const { required, disabled, loading } = props

  const placeholder = useMemo<string | undefined>(() => {
    if (!props.placeholder) return undefined
    if (!props.required) return props.placeholder
    return `${props.placeholder} *`
  }, [props.placeholder, props.required])

  const elementClassName = classnames(
    'tInput',
    {
      'tInput--filled': props.value,
      'tInput--error': touched && error,
      'tInput--touched': touched,
      'tInput--required': required,
      'tInput--disabled': disabled,
      'tInput--loading': loading,
    },
    className
  )

  const subElementClassName = classnames('tInput__group', {
    'tInput__group--filled': props.value,
    'tInput__group--error': touched && error,
    'tInput__group--touched': touched,
    'tInput__group--required': required,
    'tInput__group--disabled': disabled,
    'tInput__group--loading': loading,
  })

  return (
    <>
      <div id={id} className={elementClassName}>
        <div className={subElementClassName}>
          {inputType === 'input' && <input {...props} placeholder={placeholder} />}
          {inputType === 'textarea' && <textarea {...props} placeholder={placeholder} />}
          {touched && error && <SVG className='tInput__group__error' title='input.error' />}
        </div>
        <div className='tInput__state'>
          {touched && error && <small className='tInput__state__error'>{error}</small>}
        </div>
      </div>
    </>
  )
}

export default Input
