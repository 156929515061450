import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useDebouncedCallback } from 'use-debounce/lib'

// ///////////////////////
// SECTION: TYPES
export interface IHelpersScrollToTopProps {}

// ///////////////////////
// SECTION: EXPORTS
const ScrollToTop: React.FC<IHelpersScrollToTopProps> = (props) => {
  const visitedRoutes = useRef(
    new Map<string, [number, number]>(
      JSON.parse(window.sessionStorage.getItem('scroll_restoration')!) as [
        string,
        [number, number]
      ][]
    )
  )
  const { key, search } = useLocation()

  const saveToSession = useDebouncedCallback(() => {
    window.sessionStorage.setItem(
      'scroll_restoration',
      JSON.stringify([...visitedRoutes.current.entries()])
    )
  }, 100)

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(search)

    if (query.has('a')) return

    const values: [number, number] = visitedRoutes.current.get(key) ?? [0, 0]
    setTimeout(() => {
      window.scrollTo(...values)
    }, 1)
  }, [key, search])

  useEffect(() => {
    const setScroll = () => {
      visitedRoutes.current.set(key, [window.scrollX, window.scrollY])

      saveToSession()
    }

    window.addEventListener('scroll', setScroll)
    return () => window.removeEventListener('scroll', setScroll)
  }, [key, saveToSession])

  return null
}

export default ScrollToTop
