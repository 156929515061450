import React from 'react'
import ReactDOM from 'react-dom'
import { ParallaxProvider } from 'react-scroll-parallax'

import 'style/_all.scss'

import { SWRConfig } from 'swr'

import GlobalProvider from 'providers/Global'

import App from './App'
import reportWebVitals from './reportWebVitals'
import setupVH from './setupVH'

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        refreshInterval: 60000,
      }}
    >
      <ParallaxProvider>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </ParallaxProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
)

setupVH()
reportWebVitals(({ name, value, id }) => {
  gtag('event', name, {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
    eventLabel: id,
    nonInteraction: true,
  })
})
