import { useMemo } from 'react'
import { IntlFormatters, IntlShape, useIntl } from 'react-intl'

// ///////////////////////
// SECTION: TYPES
export interface IIntlShapeRich extends IntlShape {
  formatErrorMessage: IntlFormatters['formatMessage']
}

// ///////////////////////
// SECTION: EXPORTS
export default function useIntlRich(): IIntlShapeRich {
  const intl = useIntl()

  const value = useMemo<IIntlShapeRich>(
    () => ({
      ...intl,
      formatErrorMessage(
        descriptor: Parameters<IntlFormatters['formatMessage']>[0],
        values?: Parameters<IntlFormatters['formatMessage']>[1],
        options?: Parameters<IntlFormatters['formatMessage']>[2]
      ) {
        return intl.formatMessage(
          {
            ...descriptor,
            defaultMessage: intl.formatMessage({
              id: 'e.unknown',
            }),
          },
          values,
          options
        )
      },
    }),
    [intl]
  )

  return value
}
