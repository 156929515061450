import { useEffect, useState } from 'react'

// ///////////////////////
// SECTION: EXPORTS
export default function useWindowSize(): [width: number, height: number] {
  const [size, setSize] = useState<[width: number, height: number]>(() => [
    window.innerWidth,
    window.innerHeight,
  ])

  useEffect(() => {
    const update = () => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', update)

    return () => window.removeEventListener('resize', update)
  }, [])

  return size
}
