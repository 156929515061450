import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Parallax } from 'react-scroll-parallax'

import classnames from 'classnames'

import Theme from 'theme/_all'

// ///////////////////////
// SECTION: TYPES
export interface IComponentsCTAProps {
  id?: string
  className?: string
}

// ///////////////////////
// SECTION: EXPORTS
const CTA: React.FC<IComponentsCTAProps> = ({ id, className }) => {
  // ClassNames
  const elementClassName = classnames('cCTA', className)

  // Return
  return (
    <section id={id} className={elementClassName}>
      <Parallax
        y={['-30%', '30%']}
        styleOuter={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
        styleInner={{
          with: '100%',
          height: '100%',
        }}
      >
        <div className='_background' />
      </Parallax>
      <div className='container'>
        <div className='cCTA_card'>
          <div>
            <h2>
              <FormattedMessage id='c.cta.title' />
            </h2>
            <h3>
              <FormattedMessage id='c.cta.subtitle' />
            </h3>
          </div>
          <div>
            <Theme.Button color='blue' size='l' to='/rendez-vous'>
              <FormattedMessage id='lang.make_an_appointment' />
            </Theme.Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
