import * as yup from 'yup'

import { fetchServer, IFetchServerOptions, IFetchServerResponse } from 'utils/api'

import { IAppLocaleState } from './locale'

// ///////////////////////
// SECTION: CONTEXT
export interface IAppAuthState {
  cookieSet: boolean
  cookieConsent: boolean

  loading: boolean
  isLoggedIn: boolean
}

export interface IAppAuthStateStored {
  cookieSet: boolean
  cookieConsent: boolean

  loading: boolean
  isLoggedIn: boolean
}

export interface IAppAuthModel {}

export interface IAppAuthFunctions {
  fetchServer<T extends IFetchServerResponse>(
    endpoint: string,
    options: IFetchServerOptions,
    abortSignal?: AbortSignal
  ): Promise<T>

  cookie(value: boolean): void
}

export interface IAppAuthContext extends IAppAuthState, IAppAuthModel, IAppAuthFunctions {}

export function getAppAuthContext(): IAppAuthModel {
  return {}
}

// ///////////////////////
// SECTION: CONTEXT FUNCTIONS
export function getAppAuthContextFunctions(
  state: IAppAuthState,
  setState: React.Dispatch<React.SetStateAction<IAppAuthState>>,
  localeState: IAppLocaleState
): IAppAuthFunctions {
  return {
    fetchServer<T>(endpoint: string, options: IFetchServerOptions = {}, abortSignal?: AbortSignal) {
      return fetchServer<T>(null, localeState.active, endpoint, options, abortSignal)
    },
    cookie(value: boolean) {
      setState((oldState) => ({
        ...oldState,
        cookieSet: true,
        cookieConsent: value,
      }))
    },
  }
}

// ///////////////////////
// SECTION: INITIAL STATE
export function getAppAuthInitialState(): IAppAuthState {
  return {
    cookieSet: false,
    cookieConsent: false,

    loading: false,
    isLoggedIn: true,
  }
}

export function parseAppAuthState(value: IAppAuthStateStored): IAppAuthState {
  return {
    ...value,
  }
}

export function validateAppAuthState(value: IAppAuthStateStored): boolean {
  return yup
    .object()
    .shape({
      cookieSet: yup.boolean().required(),
      cookieConsent: yup.boolean().required(),
    })
    .isValidSync(value)
}
