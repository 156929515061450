import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { generatePath, match as Match, matchPath, useLocation, useRouteMatch } from 'react-router'

import { useGlobalContext } from 'providers/Global'

import { BASE_ROUTE, ROUTES_INFO_MAP, ROUTES_MAP, ROUTES_REVERSED_MAP } from './routes'
import { ROUTE_LOCALES_FULL, TRouteLocales, TRouteMapValue, TRouteProps } from './types'

//  ///////////////////////
//  SECTION: TYPES
export type TMetaLinkInfo = {
  rel: 'alternate' | 'canonical'
  hrefLang: TRouteLocales | 'x-default' | undefined
  href: string
}

//  ///////////////////////
//  SECTION: FUNCTIONS
const generateURL = (
  match: Match,
  location: ReturnType<typeof useLocation>,
  destination: string
): string => {
  return `${BASE_ROUTE}${generatePath(destination, match.params)}${location.search}`
}

//  ///////////////////////
//  SECTION: EXPORTS
export const useSEORouter = (): [shouldNotIndex: boolean, links: TMetaLinkInfo[]] => {
  const location = useLocation()
  const match = useRouteMatch()

  const { Locale } = useGlobalContext()!

  //  ///////////////////////
  //  SECTION: SCROLL TO ANCHOR
  useLayoutEffect(() => {
    const query = new URLSearchParams(location.search)

    if (query.has('a')) {
      const id = query.get('a')

      if (id) {
        document.getElementById(id)?.scrollIntoView(true)
      }
    }
  }, [location.search])

  //  ///////////////////////
  //  SECTION: ROUTER
  const activeRoute = useMemo<TRouteMapValue | undefined>(() => {
    let value = ROUTES_REVERSED_MAP.get(location.pathname)

    if (value) {
      for (const [path, route] of ROUTES_REVERSED_MAP.entries()) {
        if (
          matchPath(location.pathname, {
            path,
            exact: true,
          })
        ) {
          value = route
        }
      }
    }

    return value
  }, [location.pathname])

  const activeRouteInfo = useMemo<TRouteProps | undefined>(
    () => (activeRoute ? ROUTES_INFO_MAP.get(activeRoute.value) : undefined),
    [activeRoute]
  )

  const rootRouteInfo = useMemo<TRouteMapValue[] | undefined>(
    () => (activeRoute ? ROUTES_MAP.get(activeRoute.value) : undefined),
    [activeRoute]
  )

  // SECTION: Auto redirect
  useEffect(() => {
    if (activeRoute && rootRouteInfo) {
      if (
        activeRoute.type !== 'alias' &&
        activeRoute.type !== 'default' &&
        activeRoute.type !== 'redirect'
      ) {
        const newLocale = ROUTE_LOCALES_FULL[activeRoute.type]

        if (newLocale !== Locale.active) {
          Locale.change(ROUTE_LOCALES_FULL[activeRoute.type])
        }
      }
    }
  }, [Locale, activeRoute, rootRouteInfo])

  // SECTION: Generate meta links
  const links = useMemo<TMetaLinkInfo[]>(() => {
    const value: TMetaLinkInfo[] = []

    if (activeRoute && rootRouteInfo) {
      if (
        activeRoute.type === 'alias' ||
        (activeRoute.type !== 'default' && activeRoute.type !== 'redirect')
      ) {
        value.push({
          rel: activeRoute.type === 'alias' ? 'canonical' : 'alternate',
          href: generateURL(match, location, rootRouteInfo[0].value),
          hrefLang: 'x-default',
        })
      }

      const localizedURLs = new Set<TRouteLocales>()
      for (const route of rootRouteInfo) {
        if (
          route.type !== 'default' &&
          route.type !== 'alias' &&
          route.type !== 'redirect' &&
          !localizedURLs.has(route.type)
        ) {
          localizedURLs.add(route.type)

          value.push({
            rel: 'alternate',
            href: generateURL(match, location, route.value),
            hrefLang: route.type,
          })
        }
      }
    }

    return value
  }, [activeRoute, location, match, rootRouteInfo])

  return [!!activeRouteInfo?.hideSitemap, links]
}

export const useActiveLink = (): [
  activeLink: string,
  matchActiveLink: (...values: string[]) => boolean
] => {
  const location = useLocation()

  const activeLink = useMemo<string>(() => {
    const route = ROUTES_REVERSED_MAP.get(location.pathname)
    if (route) return route.value

    for (const [path, { value }] of ROUTES_REVERSED_MAP.entries()) {
      if (
        matchPath(location.pathname, {
          path,
          exact: true,
        })
      ) {
        return value
      }
    }

    return location.pathname
  }, [location.pathname])

  const matchActiveLink = useCallback(
    (...values: string[]) => {
      for (const value of values) {
        if (
          matchPath(value, {
            path: activeLink,
            exact: true,
          })
        ) {
          return true
        }
      }

      return false
    },
    [activeLink]
  )

  return [activeLink, matchActiveLink]
}
