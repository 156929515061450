import React from 'react'
import { FormattedMessage } from 'react-intl'

// ///////////////////////
// SECTION: CONFIG
let key = 0
export const RICH_TEXT_VALUES = {
  'f-bold': (...chunks: (string | React.ReactElement)[]): JSX.Element => {
    key += 1

    return (
      <span key={key} className='font-weight-700'>
        {chunks}
      </span>
    )
  },
  'c-blue-100': (...chunks: (string | React.ReactElement)[]): JSX.Element => {
    key += 1

    return (
      <span key={key} className='color-blue-100'>
        {chunks}
      </span>
    )
  },
  br: (): JSX.Element => {
    key += 1

    return <br key={key} />
  },
  bbr: (): JSX.Element => {
    key += 1

    return (
      <>
        <br key={key - 1} />
        <br key={key} />
      </>
    )
  },
  bbbr: (): JSX.Element => {
    key += 3

    return (
      <>
        <br key={key - 2} />
        <br key={key - 1} />
        <br key={key} />
      </>
    )
  },
}

// ///////////////////////
// SECTION: TYPES
export interface IHelpersFormattedRichMessageProps {
  id: string
  values?: {
    [key: string]: unknown
  }
  defaultMessage?: string
}

// ///////////////////////
// SECTION: EXPORTS
const FormattedRichMessage: React.FC<IHelpersFormattedRichMessageProps> = ({
  id,
  values,
  defaultMessage,
}) => {
  if (key >= 1000) key = 0

  return (
    <FormattedMessage
      id={id}
      values={{
        ...values,
        ...RICH_TEXT_VALUES,
      }}
      defaultMessage={defaultMessage}
    />
  )
}

export default FormattedRichMessage
