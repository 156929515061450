import React, { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import useIntlRich from 'hooks/useIntlRich'
import { useGlobalContext } from 'providers/Global'

import Loading from 'helpers/Loading'

//  //////////////////////////////////////////////
//  SECTION: TYPES
interface IProps extends RouteProps {
  message: string | boolean
}

//  //////////////////////////////////////////////
//  SECTION: EXPORTS
const LoggedOutRoute: React.FC<IProps> = ({ message, component: Component, ...rest }) => {
  const intl = useIntlRich()
  const { Auth } = useGlobalContext()!

  // Get message
  const messageStrings = useMemo<string | undefined>(() => {
    if (message === false) {
      return undefined
    }

    if (message === true) {
      return intl.formatErrorMessage({
        id: 'e.must_be_logged_out',
      })
    }

    return message
  }, [intl, message])

  // If Auth is loading, display loading screen
  if (Auth.loading) {
    return <Route {...rest} render={(props) => <Loading />} />
  }

  // If not logged in, show route
  if (!Auth.isLoggedIn) {
    // @ts-ignore
    return <Route {...rest} render={(props) => <Component {...props} />} />
  }

  // Redirect to client area
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: '/client',
            state: { referer: props.location, message: messageStrings },
          }}
        />
      )}
    />
  )
}

export default LoggedOutRoute
