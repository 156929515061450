import useWindowSize from './useWindowSize'

// ///////////////////////
// SECTION: EXPORTS
export default function useReponsive(maxWidth: number = 768, minWidth?: number): boolean {
  const [width] = useWindowSize()

  if (minWidth) {
    return maxWidth > width && width >= minWidth
  }

  return maxWidth > width
}
