import React from 'react'

import classnames from 'classnames'

import HelpersButton, { IHelpersButtonProps } from 'helpers/Button'

import SVG, { SVGTitles } from 'assets/svg/_SVG'

//  ///////////////////////
//  SECTION: TYPES
export interface IThemeButtonProps extends IHelpersButtonProps {
  id?: string
  className?: string

  align?: 'left' | 'center' | 'right'
  color?: 'blue' | 'dark' | 'white'
  size?: 's' | 'm' | 'l'

  icon?: SVGTitles
  iconSide?: 'left' | 'right'

  disabled?: boolean
  loading?: boolean
}

//  ///////////////////////
//  SECTION: EXPORTS
const Button: React.FC<IThemeButtonProps> = ({ children, ...props }) => {
  const {
    id,
    className,
    align = 'left',
    color = 'dark',
    size = 'm',
    icon,
    iconSide = 'left',
    disabled: propsDisabled,
    loading,
  } = props

  const disabled =
    typeof propsDisabled === 'boolean'
      ? propsDisabled
      : !props.to && !props.onClick && !props.submit

  const elementClassName = classnames(
    'tButton',
    `tButton--align-${align}`,
    `tButton--color-${color}`,
    `tButton--size-${size}`,
    {
      'tButton--icon': icon ?? loading,
      [`tButton--iconSide-${iconSide}`]: loading ? 'left' : iconSide,
      'tButton--disabled': disabled,
      'tButton--loading': loading,
    },
    className
  )

  return (
    <HelpersButton
      {...props}
      tabIndex={disabled || loading ? -1 : props.tabIndex}
      id={id}
      className={elementClassName}
    >
      {icon && iconSide === 'left' && !loading && <SVG className='tButton__icon' title={icon} />}
      {loading && <SVG className='tButton__loader' title='loading' />}
      {children}
      {icon && iconSide === 'right' && !loading && <SVG className='tButton__icon' title={icon} />}
    </HelpersButton>
  )
}

export default Button
