import React from 'react'

import { ReactComponent as SVGFasFaCheck } from './fas.fa-check.svg'
import { ReactComponent as SVGFasFaChevronLeft } from './fas.fa-chevron-left.svg'
import { ReactComponent as SVGFasFaCode } from './fas.fa-code.svg'
import { ReactComponent as SVGFasFaHeart } from './fas.fa-heart.svg'
import { ReactComponent as SVGFASFaTimes } from './fas.fa-times.svg'
import { ReactComponent as SVGInfo } from './info.svg'
import { ReactComponent as SVGInputError } from './input.error.svg'
import { ReactComponent as SVGLoading } from './loading.svg'
import { ReactComponent as SVGLogo } from './logo.svg'

//  ///////////////////////
//  SECTION: CONFIG
const LIST = {
  info: SVGInfo,
  'input.error': SVGInputError,
  loading: SVGLoading,
  logo: SVGLogo,
  'fas.fa-code': SVGFasFaCode,
  'fas.fa-check': SVGFasFaCheck,
  'fas.fa-chevron-left': SVGFasFaChevronLeft,
  'fas.fa-heart': SVGFasFaHeart,
  'fas.fa-times': SVGFASFaTimes,
}

//  ///////////////////////
//  SECTION: TYPES
export type SVGTitles = keyof typeof LIST

//  ///////////////////////
//  SECTION: EXPORTS
export const getSVG = (
  title: SVGTitles
): React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
> => LIST[title]

const SVG: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title: SVGTitles
  }
> = (props) => {
  const Comp = LIST[props.title]
  return <Comp {...props} title={undefined} />
}

export default SVG
