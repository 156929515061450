import React, { useCallback, useMemo } from 'react'

import * as H from 'history'
import LinkRouter from 'router/_link'

import useGoogleAnalyticsClickEvent from 'hooks/useGoogleAnalyticsClickEvent'

// ///////////////////////
// SECTION: TYPES
export type THelpersButtonBaseProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export interface IHelpersButtonProps extends THelpersButtonBaseProps {
  submit?: boolean
  to?: H.To
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void

  eventCategory?: string
  eventAction?: string
  eventLabel?: string
}

// ///////////////////////
// SECTION: EXPORTS
const Button: React.FC<IHelpersButtonProps> = ({
  submit,
  to,
  onClick,

  eventCategory,
  eventAction,
  eventLabel,

  children,

  ...props
}) => {
  const onClickEvent = useGoogleAnalyticsClickEvent(eventCategory, eventAction, eventLabel)

  const onClickCallback = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
      if (!onClick) return

      onClick(event)
      onClickEvent(event)
    },
    [onClick, onClickEvent]
  )

  const onKey = useCallback<React.KeyboardEventHandler<HTMLAnchorElement>>((e) => {
    if (e.code === 'Space') {
      e.preventDefault()
      e.stopPropagation()
      e.currentTarget.click()
    }
  }, [])

  const validatedProps = useMemo<THelpersButtonBaseProps>(
    () => ({
      id: props.id,
      className: props.className,
      style: props.style,
      tabIndex: props.tabIndex,
      disabled: props.disabled,
      'aria-label': props['aria-label'],
      'aria-disabled': props['aria-disabled'],
    }),
    [props]
  )

  const isExternalLink = useMemo<boolean>(
    () => !!/^[A-z]+:/.exec((typeof to === 'object' ? to.pathname : to) ?? ''),
    [to]
  )

  if (to) {
    if (isExternalLink) {
      return (
        <a
          {...validatedProps}
          target='_blank'
          rel='noreferrer'
          onKeyDownCapture={onKey}
          href={typeof to === 'object' ? to.pathname : to}
          onClick={onClickCallback}
        >
          {children}
        </a>
      )
    }

    return (
      <LinkRouter {...validatedProps} onKeyDownCapture={onKey} to={to} onClick={onClickCallback}>
        {children}
      </LinkRouter>
    )
  }

  if (submit)
    return (
      <button {...validatedProps} type='submit'>
        {children}
      </button>
    )

  return (
    <button {...validatedProps} type='button' onClick={onClickCallback}>
      {children}
    </button>
  )
}

export default Button
