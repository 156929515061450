import { toNonBreakSpaces } from './misc'

const Config = {
  info: {
    url: 'https://kr-mediation.be',

    address_line_1: 'Place Charles Simonet 5',
    address_line_2: '7000 Mons',
    address_url:
      'https://www.google.be/maps/place/Place+Charles+Simonet/@50.4507312,3.944404,18z/data=!3m1!4b1!4m5!3m4!1s0x47c2516ae15e8ad1:0x481354848ad524f1!8m2!3d50.4507295!4d3.9454983',

    phone_number: toNonBreakSpaces('+32 496/89.54.37'),
    email_address: 'riviere-kevin@hotmail.com',

    vat_number: toNonBreakSpaces('BE 0765.145.304'),
    honorary_account: toNonBreakSpaces('BE79 0689 4059 0233'),

    conditions_url: 'https://cajdebruxelles.be/acces-a-l-aide-de-2eme-ligne',
    ubmd_url: 'https://ubmp-bupb.org/newsite/fr/',
    commission_url: 'https://cfm-fbc.be/fr/content/code-de-bonne-conduite',
  },
  apis: {
    backend: process.env.REACT_APP_BACKEND_ENDPOINT!,

    google_maps_api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    google_recpatcha_site_key: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!,
    google_analytics_tracking_id: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!,
  },
}

export default Config
