import Button from './Button'
import Input from './Input'
import Radio from './Radio'

const Theme = {
  Button,
  Input,
  Radio,
}

export default Theme
