import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import Components from 'components/_all'

import LoggedInRoute from './_loggedInRoute'
import LoggedOutRoute from './_loggedOutRoute'
import { ROUTES } from './routes'

// ///////////////////////
// SECTION: EXPORTS
const Router: React.FC = () => {
  // Return
  return (
    <Suspense fallback={<Components.Loading />}>
      <Switch>
        {ROUTES.map((e, i) => {
          if (e.loggedIn) {
            return <LoggedInRoute key={i} message={false} {...e} />
          }

          if (e.loggedOut) {
            return <LoggedOutRoute key={i} message={false} {...e} />
          }

          return <Route key={i} {...e} />
        })}
      </Switch>
    </Suspense>
  )
}

export default Router
