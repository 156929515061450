import React, { useEffect, useRef } from 'react'

import { Loader } from '@googlemaps/js-api-loader'
import classnames from 'classnames'

import Config from 'utils/config'

import IMGIconsMarker from 'assets/img/icons.marker.png'

import GoogleMapStyle from './GoogleMap.style.json'

//  ///////////////////////
//  SECTION: CONFIG
const loader = new Loader({
  apiKey: Config.apis.google_maps_api_key,
  id: 'google_maps',
  region: 'FR',
})

const defaultMapOptions: google.maps.MapOptions = {
  mapTypeControl: false,
  styles: GoogleMapStyle as google.maps.MapTypeStyle[],
  center: {
    lat: 50.45074657360417,
    lng: 3.945503663891141,
  },
  zoom: 16,
}

// ///////////////////////
// SECTION: TYPES
export interface IComponentsGoogleMapProps extends google.maps.MapOptions {
  id?: string
  className?: string
}

// ///////////////////////
// SECTION: EXPORTS
const GoogleMap: React.FC<IComponentsGoogleMapProps> = ({ id, className, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null)

  const mapRef = useRef<google.maps.Map<Element>>()
  const markerRef = useRef<google.maps.Marker>()

  useEffect(() => {
    if (!ref.current) return

    void loader.loadPromise().then(() => {
      const mapOptions = { ...defaultMapOptions, ...(rest as google.maps.MapOptions) }

      if (mapRef.current) {
        mapRef.current.setOptions(mapOptions)
      } else {
        mapRef.current = new google.maps.Map(ref.current as Element, defaultMapOptions)
      }

      if (mapOptions.center) {
        const markerOptions: google.maps.MarkerOptions = {
          position: {
            lat:
              typeof mapOptions.center.lat === 'number'
                ? mapOptions.center.lat
                : mapOptions.center.lat(),
            lng:
              typeof mapOptions.center.lng === 'number'
                ? mapOptions.center.lng
                : mapOptions.center.lng(),
          },
          icon: {
            url: IMGIconsMarker,
            scaledSize: new google.maps.Size(50, 66),
          },
          animation: google.maps.Animation.DROP,
          map: mapRef.current,
        }

        if (markerRef.current) {
          markerRef.current.setOptions(markerOptions)
        } else {
          markerRef.current = new google.maps.Marker(markerOptions)
        }

        markerRef.current.setMap(mapRef.current)
      } else if (markerRef.current) {
        markerRef.current.setMap(null)
        markerRef.current = undefined
      }
    })

    return () => {
      loader.deleteScript()
    }
  }, [rest])

  // ClassNames
  const elementClassName = classnames('cGoogleMap', className)

  // Return
  return <div id={id} className={elementClassName} ref={ref} />
}

export default GoogleMap
