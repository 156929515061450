import React from 'react'
import { FormattedMessage } from 'react-intl'

import classnames from 'classnames'
import LinkRouter from 'router/_link'

import Config from 'utils/config'

import FormattedRichMessage from 'helpers/FormattedRichMessage'
import GoogleAnalyticsOutboundLink from 'helpers/GoogleAnalyticsOutboundLink'

import Theme from 'theme/_all'

import SVG from 'assets/svg/_SVG'

// ///////////////////////
// SECTION: TYPES
export interface IComponentsFooterProps {
  id?: string
  className?: string
}

// ///////////////////////
// SECTION: EXPORTS
const Footer: React.FC<IComponentsFooterProps> = ({ id, className }) => {
  const elementClassName = classnames('cFooter', className)

  return (
    <footer id={id} className={elementClassName}>
      <div className='cFooter__content container'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
            <LinkRouter className='cFooter__content__brand' to='/'>
              <SVG title='logo' />
            </LinkRouter>
            <p>
              <FormattedRichMessage id='c.footer.info.name' />
            </p>
            <p>
              <FormattedRichMessage
                id='c.footer.info.vat_number'
                values={{
                  vat_number: Config.info.vat_number,
                }}
              />
              <br />
              <FormattedRichMessage
                id='c.footer.info.honorary_account'
                values={{
                  honorary_account: Config.info.honorary_account,
                }}
              />
            </p>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
            <LinkRouter className='link link--white' to={Config.info.address_url}>
              <span>{Config.info.address_line_1}</span>
              <br />
              <span>{Config.info.address_line_2}</span>
            </LinkRouter>
            <p>
              <FormattedRichMessage
                id='c.footer.contact.phone_number'
                values={{
                  phone_number: (
                    <LinkRouter className='link link--white' to={`tel:${Config.info.phone_number}`}>
                      {Config.info.phone_number}
                    </LinkRouter>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedRichMessage
                id='c.footer.contact.email_address'
                values={{
                  email_address: (
                    <LinkRouter
                      className='link link--white'
                      to={`mailto:${Config.info.email_address}`}
                    >
                      {Config.info.email_address}
                    </LinkRouter>
                  ),
                }}
              />
            </p>
          </div>
          <div className='cFooter__separator col-lg-3 col-md-6 col-12 mt-lg-0 mt-md-4 mt-4'>
            <ul>
              <li>
                <LinkRouter className='link link--white' to='/la-mediation'>
                  <FormattedMessage id='lang.what_is_mediation' />
                </LinkRouter>
              </li>
              <li>
                <LinkRouter className='link link--white' to='/les-avantages'>
                  <FormattedMessage id='lang.the_advantages' />
                </LinkRouter>
              </li>
              <li>
                <LinkRouter className='link link--white' to='/quels-litiges'>
                  <FormattedMessage id='lang.for_wich_disputes' />
                </LinkRouter>
              </li>
              <li>
                <LinkRouter className='link link--white' to='/votre-mediateur'>
                  <FormattedMessage id='lang.your_mediator' />
                </LinkRouter>
              </li>
              <li>
                <LinkRouter className='link link--white' to='/honoraires'>
                  <FormattedMessage id='lang.fees' />
                </LinkRouter>
              </li>
            </ul>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mt-lg-0 mt-md-4 mt-4'>
            <Theme.Button color='white' to='/rendez-vous'>
              <FormattedMessage id='lang.make_an_appointment' />
            </Theme.Button>
          </div>
        </div>
      </div>
      <div className='cFooter__recaptcha container-fluid'>
        <p>
          <FormattedMessage id='c.footer.recaptcha' />
        </p>
        <LinkRouter
          className='link link--white'
          to='https://www.google.com/intl/fr/policies/privacy/'
        >
          <FormattedMessage id='c.footer.recaptcha.privacy' />
        </LinkRouter>
        <LinkRouter
          className='link link--white'
          to='https://www.google.com/intl/fr/policies/terms/'
        >
          <FormattedMessage id='c.footer.recaptcha.terms' />
        </LinkRouter>
      </div>
      <div className='cFooter__legals container-fluid'>
        <LinkRouter className='link link--white' to='/vie-privee'>
          <FormattedMessage id='lang.privacy_policy' />
        </LinkRouter>
        <LinkRouter className='link link--white' to='/mentions-legales'>
          <FormattedMessage id='lang.legal_disclaimer' />
        </LinkRouter>
      </div>
      <div id='mds' className='container-fluid'>
        <GoogleAnalyticsOutboundLink to='https://www.mds-digitalagency.be/' eventLabel='footer_mds'>
          <div className='_copyright'>
            <SVG title='fas.fa-code' className='_copyright__code' height={17} />
            {'by '}
            <span>MDS Digital Agency</span>
            {' with'}
            <SVG title='fas.fa-heart' className='_copyright__heart' height={17} />
          </div>
          <div className='_copyright__colored'>
            <SVG title='fas.fa-code' className='_copyright__code' height={17} />
            {'by '}
            <span>MDS Digital Agency</span>
            {' with'}
            <SVG title='fas.fa-heart' className='_copyright__heart' height={17} />
          </div>
        </GoogleAnalyticsOutboundLink>
      </div>
    </footer>
  )
}

export default Footer
