import React, { createContext, useContext, useMemo } from 'react'

import useLocalState from 'hooks/useLocalState'

import {
  getAppAuthContext,
  getAppAuthContextFunctions,
  getAppAuthInitialState,
  IAppAuthContext,
  IAppAuthState,
  IAppAuthStateStored,
  parseAppAuthState,
  validateAppAuthState,
} from 'services/auth'
import {
  getAppLocaleContext,
  getAppLocaleContextFunctions,
  getAppLocaleInitialState,
  IAppLocaleContext,
  IAppLocaleState,
  IAppLocaleStateStored,
  parseAppLocaleState,
  validateAppLocaleState,
} from 'services/locale'

// ///////////////////////
// SECTION: TYPES
interface IGlobalContext {
  Locale: IAppLocaleContext
  Auth: IAppAuthContext
}

// ///////////////////////
// SECTION: CONTEXT
export const GlobalContext = createContext<IGlobalContext | undefined>(undefined)

// ///////////////////////
// SECTION: HOOK
export function useGlobalContext(): IGlobalContext | undefined {
  return useContext(GlobalContext)
}

// ///////////////////////
// SECTION: PROVIDER
const GlobalProvider: React.FC = ({ children }) => {
  // ///////////////////////
  // LOCALE
  const [Locale, setLocale] = useLocalState<IAppLocaleState, IAppLocaleStateStored>(
    'locale',
    getAppLocaleInitialState,
    parseAppLocaleState,
    validateAppLocaleState
  )
  // ///////////////////////

  // ///////////////////////
  // AUTH
  const [Auth, setAuth] = useLocalState<IAppAuthState, IAppAuthStateStored>(
    'auth',
    getAppAuthInitialState,
    parseAppAuthState,
    validateAppAuthState
  )
  // ///////////////////////

  // ///////////////////////
  // MEMOS
  const LocaleContext = useMemo<IAppLocaleContext>(
    () => ({
      ...Locale,
      ...getAppLocaleContext(),
      ...getAppLocaleContextFunctions(Locale, setLocale),
    }),
    [Locale, setLocale]
  )

  const AuthContext = useMemo<IAppAuthContext>(
    () => ({
      ...Auth,
      ...getAppAuthContext(),
      ...getAppAuthContextFunctions(Auth, setAuth, Locale),
    }),
    [Auth, setAuth, Locale]
  )

  // ///////////////////////
  // RETURN
  const Context = useMemo<IGlobalContext>(
    () => ({
      Locale: LocaleContext,
      Auth: AuthContext,
    }),
    [LocaleContext, AuthContext]
  )

  return <GlobalContext.Provider value={Context}>{children}</GlobalContext.Provider>
}

export default GlobalProvider
