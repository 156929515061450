import * as yup from 'yup'

// ///////////////////////
// SECTION: CONFIG
export const LOCALES_LIST = ['fr-FR'] as const
export const LOCALES_LIST_UNTYPED = (LOCALES_LIST as unknown) as string[]

export type TLocales = typeof LOCALES_LIST[number]

export type TLocalizedType<T> = {
  [key in TLocales]: T
}

// ///////////////////////
// SECTION: CONTEXT
export interface IAppLocaleState {
  isSet: boolean
  active: TLocales
}

export interface IAppLocaleStateStored {
  isSet: boolean
  active: string
}

export interface IAppLocaleModel {}

export interface IAppLocaleFunctions {
  change(id: TLocales): void
  reset(): void
}

export interface IAppLocaleContext extends IAppLocaleState, IAppLocaleModel, IAppLocaleFunctions {}

export function getAppLocaleContext(): IAppLocaleModel {
  return {}
}

// ///////////////////////
// SECTION: CONTEXT FUNCTIONS
export function getAppLocaleContextFunctions(
  state: IAppLocaleState,
  setState: React.Dispatch<React.SetStateAction<IAppLocaleState>>
): IAppLocaleFunctions {
  return {
    change(id: TLocales) {
      setState((oldState) => ({
        ...oldState,
        isSet: true,
        active: id,
      }))
    },
    reset() {
      setState(getAppLocaleInitialState())
    },
  }
}

// ///////////////////////
// SECTION: INITIAL STATE
export function getAppLocaleInitialState(): IAppLocaleState {
  let active: TLocales = LOCALES_LIST[0]

  if (LOCALES_LIST_UNTYPED.includes(navigator.language)) {
    active = navigator.language as TLocales
  }

  return {
    isSet: false,
    active,
  }
}

export function parseAppLocaleState(value: IAppLocaleStateStored): IAppLocaleState {
  return {
    isSet: value.isSet,
    active: value.active as TLocales,
  }
}

export function validateAppLocaleState(value: IAppLocaleStateStored): boolean {
  return yup
    .object()
    .shape({
      isSet: yup.boolean().required(),
      active: yup.string().oneOf(LOCALES_LIST_UNTYPED).required(),
    })
    .isValidSync(value)
}
