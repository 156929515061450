import React from 'react'
import { generatePath, Redirect } from 'react-router'

import { IRoute, ROUTE_LOCALES, TRouteLocales, TRouteMapValue, TRouteProps } from './types'

//  ///////////////////////
//  SECTION: TYPES
export type TMetaLinkInfo = {
  rel: 'alternate'
  hrefLang: TRouteLocales | 'x-default'
  href: string
}

//  ///////////////////////
//  SECTION: FUNCTIONS
const addToMap = (
  map: Map<
    string,
    {
      type: TRouteProps['type']
      value: string
    }[]
  >,
  k: string,
  v: {
    type: TRouteProps['type']
    value: string
  }
) => {
  map.set(k, [...(map.get(k) ?? []), v])
}

//  ///////////////////////
//  SECTION: EXPORTS
export const flattenRoute = (
  input: IRoute,
  base: string = '',
  localizedPath?: IRoute['localizedPath']
): TRouteProps[] => {
  const routes: TRouteProps[] = []

  if (input.path === '*') {
    return routes
  }

  const path = `${base}${input.path}`

  routes.push({
    path,
    root: path,
    type: 'default',
    component: input.options.element,
    sensitive: input.options.caseSensitive,
    loggedIn: input.options.loggedIn,
    loggedOut: input.options.loggedOut,
    hideSitemap: input.options.hideSitemap ?? input.options.loggedIn,
    exact: true,
  })

  if (input.redirects) {
    for (const redirect of input.redirects) {
      const subPath = redirect.startsWith(':') ? redirect.slice(1) : `${base}${redirect}`

      routes.push({
        path: subPath,
        root: path,
        type: 'redirect',
        render: (props) => {
          return <Redirect to={generatePath(path, props.match.params)} />
        },
        hideSitemap: true,
        sensitive: true,
        exact: true,
      })
    }
  }

  if (input.aliases) {
    for (const alias of input.aliases) {
      const subPath = alias.startsWith(':') ? alias.slice(1) : `${base}${alias}`
      const Component = input.options.element
      routes.push({
        path: subPath,
        root: path,
        type: 'alias',
        component: Component,
        sensitive: true,
        loggedIn: input.options.loggedIn,
        loggedOut: input.options.loggedOut,
        hideSitemap: input.options.hideSitemap ?? input.options.loggedIn,
        exact: true,
      })
    }
  }

  for (const key of ROUTE_LOCALES) {
    routes.push({
      path: `/${key}${localizedPath?.[key] ?? base}${input.localizedPath?.[key] ?? input.path}`,
      root: path,
      type: key,
      component: input.options.element,
      sensitive: input.options.caseSensitive,
      loggedIn: input.options.loggedIn,
      loggedOut: input.options.loggedOut,
      hideSitemap: input.options.hideSitemap ?? input.options.loggedIn,
      exact: true,
    })
  }

  if (input.subRoutes) {
    const subLocalizedPath: IRoute['localizedPath'] = {}

    if (input.localizedPath) {
      for (const [k, v] of Object.entries(input.localizedPath)) {
        const key = k as TRouteLocales

        if (v) {
          subLocalizedPath[key] = `${localizedPath?.[key] ?? base}${v}`
        }
      }
    }

    for (const route of input.subRoutes) {
      const result = flattenRoute(route, path, subLocalizedPath)
      routes.push(...result)
    }
  }

  return routes
}

export const flattenRoutes = (
  input: IRoute[]
): [
  routes: TRouteProps[],
  map: Map<string, TRouteMapValue[]>,
  reveresedMap: Map<string, TRouteMapValue>,
  infoMap: Map<string, TRouteProps>
] => {
  const routes: TRouteProps[] = []

  const routesMap = new Map<string, TRouteMapValue[]>()
  const routesReversedMap = new Map<string, TRouteMapValue>()
  const routesInfoMap = new Map<string, TRouteProps>()

  for (const route of input) {
    const result = flattenRoute(route, '')

    routes.push(...result)
  }

  const e404 = input.find((e) => e.path === '*')

  routes.push({
    root: '*',
    type: 'default',
    component: e404?.options.element,
  })

  for (const route of routes) {
    if (route.path) {
      if (typeof route.path === 'string') {
        addToMap(routesMap, route.root, { type: route.type, value: route.path })
        routesReversedMap.set(route.path, { type: route.type, value: route.root })
        routesInfoMap.set(route.path, route)
      } else {
        for (const path of route.path) {
          addToMap(routesMap, route.root, { type: route.type, value: path })
          routesReversedMap.set(path, { type: route.type, value: route.root })
          routesInfoMap.set(path, route)
        }
      }
    }
  }

  return [routes, routesMap, routesReversedMap, routesInfoMap]
}
