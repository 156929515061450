import { useEffect, useRef } from 'react'

// ///////////////////////
// SECTION: EXPORTS
export default function useOutisdeClickEvent<T extends HTMLElement>(
  callback: (e: React.MouseEvent<T>) => unknown
): React.MutableRefObject<T | null> {
  const ref = useRef<T>(null)

  useEffect(() => {
    function handleClickOutside(e: React.MouseEvent<T>) {
      if (!ref.current) return true

      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        callback(e)
      }

      return true
    }

    // @ts-ignore
    document.addEventListener('click', handleClickOutside)
    // @ts-ignore
    document.addEventListener('focusin', handleClickOutside)

    return () => {
      // @ts-ignore
      document.removeEventListener('click', handleClickOutside)
      // @ts-ignore
      document.removeEventListener('focusin', handleClickOutside)
    }
  }, [callback, ref])

  return ref
}
