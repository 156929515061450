import React from 'react'

import ComponentsLoading from 'components/Loading'

// ///////////////////////
// SECTION: EXPORTS
const Loading: React.FC = () => {
  return (
    <div className='view vLoading'>
      <ComponentsLoading />
    </div>
  )
}

export default Loading
