export const isFunction = (functionToCheck: unknown): functionToCheck is Function => {
  return !!functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
}

export const isPromise = <T = unknown>(object: unknown): object is Promise<T> => {
  if (Promise && Promise.resolve) {
    return Promise.resolve(object) === object
  }

  return false
}

export const toNonBreakSpaces = (value: string): string => {
  while (value.includes(' ')) {
    value = value.replace(' ', '\u00A0')
  }

  return value
}
