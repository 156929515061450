import { useCallback } from 'react'

import { gaEvent } from 'utils/analytics'

// ///////////////////////
// SECTION: EXPORTS
export default function useGoogleAnalyticsOutboundLink(
  label?: string
): (event: React.MouseEvent<HTMLElement, MouseEvent>) => void {
  return useCallback(() => {
    if (label) {
      gaEvent({
        category: 'click',
        action: 'outbound',
        label: label ?? 'no label',
        value: 1,
      })
    }
  }, [label])
}
