import React, { useCallback } from 'react'

import useGoogleAnalyticsOutboundLink from 'hooks/useGoogleAnalyticsClickOutboundLink'

// ///////////////////////
// SECTION: TYPES
export interface IHelpersGoogleAnalyticsOutboundLinkProps
  extends React.HTMLProps<HTMLAnchorElement> {
  eventLabel?: string
  to: React.HTMLProps<HTMLAnchorElement>['href']
}

// ///////////////////////
// SECTION: EXPORTS
const GoogleAnalyticsOutboundLink: React.FC<IHelpersGoogleAnalyticsOutboundLinkProps> = ({
  children,
  eventLabel,
  to,
  ...props
}) => {
  const onClickEvent = useGoogleAnalyticsOutboundLink(eventLabel)
  const onClickCallBack = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      onClickEvent(event)

      if (props.onClick) {
        props.onClick(event)
      }
    },
    [onClickEvent, props]
  )

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a {...props} href={to} rel='noopener, noreferrer' onClick={onClickCallBack}>
      {children}
    </a>
  )
}

export default GoogleAnalyticsOutboundLink
