import React, { useMemo } from 'react'
import { generatePath, Link, LinkProps, match as Match, matchPath } from 'react-router-dom'

import * as H from 'history'

import { useGlobalContext } from 'providers/Global'

import { ROUTES_MAP } from './routes'
import { TRouteMapValue } from './types'

//  ///////////////////////
//  SECTION: FUNCTION
const findRoutesWithMatch = (
  pathname: string
):
  | [
      routes: TRouteMapValue[],
      match: Match<{}>,
      query: string | undefined,
      anchor: string | undefined
    ]
  | undefined => {
  let query: string | undefined

  if (pathname.includes('?')) {
    const result = pathname.split('?')
    // eslint-disable-next-line prefer-destructuring
    pathname = result[0]
    query = `?${result[1]}`
  }

  let anchor: string | undefined

  if (pathname.includes('#')) {
    const result = pathname.split('#')
    // eslint-disable-next-line prefer-destructuring
    pathname = result[0]
    anchor = `#${result[1]}`
  }

  let routes = ROUTES_MAP.get(pathname)

  if (routes) {
    return [
      routes,
      matchPath(pathname, {
        path: pathname,
        exact: true,
      })!,
      query,
      anchor,
    ]
  }

  let match: Match<{}> | null = null

  for (const [path, value] of ROUTES_MAP.entries()) {
    match = matchPath(pathname, {
      path,
      exact: true,
    })

    if (match) {
      routes = value

      break
    }
  }

  if (!routes || !match) return undefined

  return [routes, match, query, anchor]
}

const pathToPartialPath = (path: H.To): H.PartialPath =>
  typeof path === 'string' ? { pathname: path } : path

//  ///////////////////////
//  SECTION: EXPORTS
const LinkRouter: React.FC<LinkProps> = (props) => {
  const { Locale } = useGlobalContext()!

  const to = useMemo<H.PartialPath>(() => {
    const value = props.to as H.To
    if (typeof value !== 'string' && !value.pathname) return value

    const pathname = typeof value === 'string' ? value : value.pathname
    if (!pathname) return pathToPartialPath(value)

    if (pathname.startsWith('http')) {
      return pathToPartialPath(pathname)
    }

    const result = findRoutesWithMatch(pathname)
    if (!result) return pathToPartialPath(value)
    const [routes, match, query, anchor] = result

    const redirectRoute = {
      ...routes.find((e) => e.type === Locale.active.slice(0, 2)),
    }
    if (!redirectRoute) return pathToPartialPath(value)

    if (typeof value === 'string') {
      return pathToPartialPath({
        pathname: generatePath(redirectRoute.value!, match.params) + (anchor ?? ''),
        search: query,
      })
    }

    return {
      ...value,
      pathname: generatePath(redirectRoute.value!, match.params) + (anchor ?? ''),
      search: query,
    }
  }, [Locale.active, props.to])

  const isExternalLink = useMemo<boolean>(
    () => (to.pathname ? !!/^\w+:/.exec(to.pathname) : false),
    [to]
  )

  return (
    <Link
      {...props}
      to={to}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener, noreferrer' : undefined}
    />
  )
}

export default LinkRouter
