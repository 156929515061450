import { useEffect, useRef, useState } from 'react'

import { isFunction } from 'utils/misc'

// ///////////////////////
// SECTION: EXPORTS
export default function useLocalState<S, T = S>(
  key: string,
  initialState: S | ((key?: string) => S),
  parser?: (value: T) => S,
  validation?: (value: T) => boolean,
  serialize?: (value: S) => T
): [state: S, setState: React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState<S>(() => {
    try {
      const value = localStorage.getItem(`state_${key}`)

      if (value) {
        const valueParsed = JSON.parse(value) as T

        if (!validation || validation(valueParsed)) {
          if (parser) {
            return parser(valueParsed)
          }

          return (valueParsed as unknown) as S
        }
      }
    } catch {
      // NOTHING
    }

    return isFunction(initialState) ? initialState(`state_${key}`) : initialState
  })

  const savedState = useRef<S | null>(null)

  // Set state to LocalStorage
  useEffect(() => {
    if (state !== savedState.current) {
      savedState.current = state

      localStorage.setItem(`state_${key}`, JSON.stringify(serialize ? serialize(state) : state))
    }
  }, [key, serialize, state])

  // Return
  return [state, setState]
}
