import { useCallback } from 'react'

import { gaEvent } from 'utils/analytics'

// ///////////////////////
// SECTION: EXPORTS
export default function useGoogleAnalyticsClickEvent(
  category?: string,
  action?: string,
  label?: string
): (event: React.MouseEvent<HTMLElement, MouseEvent>) => void {
  return useCallback(() => {
    if (category && action) {
      gaEvent({
        category: category ?? 'click',
        action: action ?? 'internal',
        label: label ?? 'click',
        value: 1,
      })
    }
  }, [action, category, label])
}
