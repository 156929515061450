import React from 'react'

import classnames from 'classnames'

// ///////////////////////
// SECTION: TYPES
export interface IComponentsLoadingProps {
  id?: string
  className?: string
}

// ///////////////////////
// SECTION: EXPORTS
const Loading: React.FC<IComponentsLoadingProps> = ({ id, className }) => {
  const elementClassName = classnames('cLoading', className)

  return (
    <div id={id} className={elementClassName}>
      <div className='cLoading__loader' />
    </div>
  )
}

export default Loading
