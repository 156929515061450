import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import classnames from 'classnames'
import LinkRouter from 'router/_link'
import { useActiveLink } from 'router/hooks'

import useFocusAction from 'hooks/useFocusAction'
import useIntlRich from 'hooks/useIntlRich'
import useOutisdeClickEvent from 'hooks/useOutsideClickEvent'
import useReponsive from 'hooks/useResponsive'

import Theme from 'theme/_all'

import SVG from 'assets/svg/_SVG'

// ///////////////////////
// SECTION: TYPES
export interface IComponentsNavbarProps {
  id?: string
  className?: string
}

//  ///////////////////////
//  SECTION: EXPORTS
const Navbar: React.FC<IComponentsNavbarProps> = ({ id, className }) => {
  const intl = useIntlRich()
  const location = useLocation()
  const [, matchActiveLink] = useActiveLink()
  const isSmallNavbar = useReponsive(1200, 768)

  const [isExpanded, setExpanded] = useState<boolean>(false)
  const onMouse = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    setExpanded((v) => !v)
  }, [])
  const [onKeyboard] = useFocusAction(useCallback(() => setExpanded((v) => !v), []))

  useEffect(() => {
    setExpanded(false)
  }, [location.pathname])

  const ref = useOutisdeClickEvent<HTMLElement>(() => setExpanded(false))

  const elementClassName = classnames(
    'cNavbar',
    {
      'cNavbar--expanded': isExpanded,
      'cNavbar--small': isSmallNavbar,
    },
    className
  )

  return (
    <nav ref={ref} id={id} className={elementClassName}>
      <div className='container'>
        <LinkRouter
          aria-label={intl.formatMessage({ id: 'lang.home' })}
          to='/'
          className='cNavbar__brand'
        >
          <SVG title='logo' />
        </LinkRouter>
        <div
          className='cNavbar__collapse'
          role='button'
          tabIndex={0}
          onClick={onMouse}
          onKeyPress={onKeyboard}
          aria-label='navbar'
        >
          <div />
        </div>
        {isSmallNavbar && (
          <div className='cNavbar__cta'>
            <Theme.Button size='s' to='/rendez-vous'>
              <FormattedMessage id='lang.make_an_appointment' />
            </Theme.Button>
          </div>
        )}
        <div className='cNavbar__content'>
          <ul>
            <li
              className={classnames(
                'cNavbar__link',
                matchActiveLink('/la-mediation') && 'cNavbar__link--active'
              )}
            >
              <LinkRouter to='/la-mediation'>
                <FormattedMessage id='lang.the_mediation' />
              </LinkRouter>
            </li>
            <li
              className={classnames(
                'cNavbar__link',
                matchActiveLink('/les-avantages') && 'cNavbar__link--active'
              )}
            >
              <LinkRouter to='/les-avantages'>
                <FormattedMessage id='lang.the_advantages' />
              </LinkRouter>
            </li>
            <li
              className={classnames(
                'cNavbar__link',
                matchActiveLink('/quels-litiges') && 'cNavbar__link--active'
              )}
            >
              <LinkRouter to='/quels-litiges'>
                <FormattedMessage id='lang.for_wich_disputes' />
              </LinkRouter>
            </li>
            <li
              className={classnames(
                'cNavbar__link',
                matchActiveLink('/votre-mediateur') && 'cNavbar__link--active'
              )}
            >
              <LinkRouter to='/votre-mediateur'>
                <FormattedMessage id='lang.your_mediator' />
              </LinkRouter>
            </li>
            <li
              className={classnames(
                'cNavbar__link',
                matchActiveLink('/honoraires') && 'cNavbar__link--active'
              )}
            >
              <LinkRouter to='/honoraires'>
                <FormattedMessage id='lang.fees' />
              </LinkRouter>
            </li>
            {!isSmallNavbar && (
              <li
                className={classnames(
                  'cNavbar__link',
                  'cNavbar__link--cta',
                  matchActiveLink('/rendez-vous') && 'cNavbar__link--active'
                )}
              >
                <Theme.Button size='s' to='/rendez-vous'>
                  <FormattedMessage id='lang.make_an_appointment' />
                </Theme.Button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
