import React from 'react'
import { RouteComponentProps, RouteProps } from 'react-router'

import { TLocales } from 'services/locale'

//  ///////////////////////
//  SECTION: CONFIG
export const ROUTE_LOCALES = ['fr'] as const
export const ROUTE_LOCALES_FULL: {
  [key in TRouteLocales]: TLocales
} = {
  fr: 'fr-FR',
}

//  ///////////////////////
//  SECTION: TYPES

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TRouteComponentProps = RouteComponentProps<any>
export type TRouteProps = RouteProps & {
  root: string
  type: TRouteLocales | 'redirect' | 'alias' | 'default'
  hideSitemap?: boolean

  loggedIn?: boolean
  loggedOut?: boolean
}

export type TRouteLocales = typeof ROUTE_LOCALES[number]

export interface IRoute {
  path: string

  options: {
    element: React.FC<TRouteComponentProps>

    caseSensitive?: boolean

    loggedIn?: boolean
    loggedOut?: boolean

    hideSitemap?: boolean
  }

  redirects?: string[]
  aliases?: string[]
  localizedPath?: {
    [key in TRouteLocales]?: string
  }

  subRoutes?: IRoute[]
}

export type TRouteMapValue = {
  type: TRouteProps['type']
  value: string
}

export type TRouter = IRoute[]
